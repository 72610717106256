import moment from 'moment';

const statsToFilterYears = (from: string, to: string) => {
    let yearFrom = moment(from).get('year');
    const yearTo = moment(to).get('year');
    const range = [yearFrom];

    while (yearFrom < yearTo) {
        range.push(yearFrom += 1);
    }

    return range;
};

const yearToDateRange = (year: number) => ({
    from: moment([year]).startOf('year').format(),
    to: moment([year]).endOf('year').format(),
});

export {statsToFilterYears, yearToDateRange};
