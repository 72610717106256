import {
    useEffect,
    useState,
    useMemo,
    ReactNode,
    useCallback,
} from 'react';
import {useQuery, useReactiveVar, useLazyQuery} from '@apollo/client';
import store from 'store/dist/store.modern';
import {PageProps, navigate} from 'gatsby';
import {Filter, FilterDimensionsData} from '../../../interfaces/filters';
import {
    GetMutationsDocument,
    GetVideoFiltersDocument,
    GetVideoFiltersQuery,
    OrderWay,
    VideoMutationOrderBy,
    VideoVersion,
} from '../../../graphql-types';
import {ActionButtons, Layout} from '../../../components/layout';
import {Button, Tabs} from '../../../components/core';
import {AddIcon, XlsIcon} from '../../../components/core/icons';
import ButtonGroup from '../../../components/core/button/buttonGroup';
import Table from '../../../components/core/table/table';
import TableFooter from '../../../components/core/table/tableFooter';
import FullFilter from '../../../components/modules/fullFilter/fullFilter';
import useChannelTabsData, {ChannelTabsVariant} from '../../../hooks/useChannelTabsData';
import AddVideoModal from '../../../modals/videos/addVideo';
import VideosExportModal from '../../../modals/videos/videosExport';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useFilter from '../../../hooks/useFilter';
import {tableVideosMainColumns} from '../../../configs/pages/videos';
import {VideoModalVariants} from '../../../configs/pages/videos/modals';
import getFilterAttributes from '../../../helpers/filters/getFilterAttributes';
import {accountVar} from '../../../helpers/graphql/variables';
import {ColumnSortingActive} from '../../../interfaces/table';
import toastify, {ToastLocationState} from '../../../helpers/toast/toastify';
import {statsToFilterYears, yearToDateRange} from '../../../helpers/filters/yearFilter';

type ChannelVideosPageType = {
    channel: string,
};
type ChannelVideosPageProps = PageProps<null, null, ToastLocationState> & ChannelVideosPageType;

const ChannelVideosPage = ({channel, location}: ChannelVideosPageProps) => {
    const account = useReactiveVar(accountVar);
    const [paging, setPaging] = useState({
        limit: store.get('userSettings')?.showPerPage || 10,
        page: 1,
    });
    const [openedModal, setOpenedModal] = useState<VideoModalVariants | null>(null);
    const channelTabsData = useChannelTabsData(ChannelTabsVariant.videos);
    const [localStorageFilters, setLocalStorageFilters] = useLocalStorage<Filter | null>('filterVideosTable');
    const [searchQuery, setSearchQuery] = useState('');
    const {data: videoFiltersData} = useQuery(GetVideoFiltersDocument);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState<string[] | null>(null);
    const [activeSorting, setActiveSorting] = useState<ColumnSortingActive<VideoMutationOrderBy>>({});
    const [mutationsToExport, setMutationsToExport] = useState<string[] | 'all'>([]);
    const {
        filters,
        loaded: filtersLoaded,
        toggleFilter,
        setFilter,
        setAllFilters,
    } = useFilter(localStorageFilters || undefined);

    const currentChannelName = useMemo(() => {
        if (!channelTabsData) {
            return '';
        }

        const currentChannel = channelTabsData.filter(item => item.id === channel)[0];

        return currentChannel?.title || '';
    }, [channelTabsData, channel]);

    const getQueryVariables = (resetPaging?: boolean) => ({
        paging: resetPaging ? {limit: paging.limit, page: 1} : paging,
        filter: {
            channelIds: [channel],
            themeIds: filters.themeAttributes as string[],
            typeIds: filters.typeAttributes as string[],
            languageIds: filters.languages as string[],
            uploadPolicyIds: filters.uploadPolicyAttributes as string[],
            version: filters.versionAttributes as VideoVersion[],
            mutationCreatedAtRange: filters.year?.length === 1 ? yearToDateRange(Number(filters.year[0])) : undefined,
            fulltext: searchQuery,
        },
        order: {
            by: activeSorting.column,
            way: activeSorting.desc ? OrderWay.Desc : OrderWay.Asc,
        },
    });

    const [
        fetchMutations, {
            data,
            error,
            loading,
        },
    ] = useLazyQuery(GetMutationsDocument, {
        fetchPolicy: 'cache-and-network',
    });

    const videoFilters = useMemo(() => {
        if (!videoFiltersData) {
            return {};
        }

        const toReturn = getFilterAttributes<GetVideoFiltersQuery>(videoFiltersData);

        const yearsRange = statsToFilterYears(data?.videoStats.minMutationCreatedAt, data?.videoStats.maxMutationCreatedAt);

        toReturn.year = {
            name: 'Year',
            type: 'radio',
            values: [
                {
                    value: 'Off',
                    name: 'year',
                }, ...yearsRange.map(value => ({
                    value,
                    name: 'year',
                })),
            ],
        };

        return toReturn;
    }, [videoFiltersData, data]);

    const hasVideoWritePermission = useMemo(() => (
        account ? (account.permissions.videos?.write || false) : false
    ), [account]);

    const getBulkActionButtons = useCallback(() => (
        <ButtonGroup>
            <Button
                text="Export video data"
                icon={XlsIcon}
                onClick={() => setOpenedModal(VideoModalVariants.export)}
                size="tiny"
                color="white"
            />
            {hasVideoWritePermission && (
                <Button
                    text="Mass edit"
                    icon={XlsIcon}
                    onClick={() => navigate('../mass-edit', {state: {mutationIds: selectedRows}})}
                    size="tiny"
                    color="white"
                />
            )}
        </ButtonGroup>
    ) as ReactNode, [
        setOpenedModal,
        hasVideoWritePermission,
        selectedRows,
    ]);

    const tableColumns = useMemo(() => tableVideosMainColumns({
        channel,
        setMutationsToExport,
        setOpenedModal,
        sorting: {
            enabled: [
                VideoMutationOrderBy.VideoMcbt,
                VideoMutationOrderBy.VideoThemeName,
                VideoMutationOrderBy.Title,
                VideoMutationOrderBy.VideoTypeName,
            ],
            active: activeSorting,
            setSorting: setActiveSorting,
        },
        hasVideoWritePermission,
    }), [
        activeSorting,
        channel,
        hasVideoWritePermission,
    ]);

    useEffect(() => {
        setSelectedRows(null);
        setMutationsToExport([]);
    }, [channel]);

    const handleApplyFilters = (): void => {
        setLocalStorageFilters(filters);

        fetchMutations({variables: getQueryVariables(true)});
    };

    useEffect(() => {
        if (!filtersLoaded) {
            return;
        }

        fetchMutations({variables: getQueryVariables(true)});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersLoaded]);

    useEffect(() => {
        fetchMutations({variables: getQueryVariables(true)});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchQuery,
        channel,
        activeSorting.column,
        activeSorting.desc,
    ]);

    useEffect(() => {
        fetchMutations({variables: getQueryVariables()});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging]);

    useEffect(() => {
        if (!location?.state?.toast) {
            return;
        }

        toastify(location.state.toast);
        window.history.replaceState(null, '');
    }, [location?.state?.toast]);

    return (
        <>
            <Layout
                title="Video manager"
                scope="videos"
            >
                <Tabs data={channelTabsData} />
                <ActionButtons
                    buttonsHidden={filtersOpen}
                >
                    <Button
                        text="Export all data (filters applied)"
                        icon={XlsIcon}
                        onClick={() => {
                            setMutationsToExport('all');
                            setOpenedModal(VideoModalVariants.export);
                        }
                        }
                        color='white'
                    />
                    {hasVideoWritePermission && (
                        <Button
                            onClick={() => navigate('../bulk-import', {state: {channel, currentChannelName}})}
                            text="Bulk Import"
                            color='white'
                        />
                    )}
                    {hasVideoWritePermission && (
                        <Button
                            onClick={() => setOpenedModal(VideoModalVariants.add)}
                            text="Create a new video"
                            icon={AddIcon}
                            disabled={filtersOpen}
                        />
                    )}
                </ActionButtons>
                <FullFilter
                    data={videoFilters as FilterDimensionsData}
                    filters={filters}
                    filterMethods={{
                        toggleFilter,
                        setAllFilters,
                        applyFilters: handleApplyFilters,
                        setFilter,
                    }}
                    search={{
                        query: searchQuery,
                        applyQuery: setSearchQuery,
                    }}
                    onOpenStateChange={setFiltersOpen}
                    render={() => (
                        <>
                            <Table
                                settings={{
                                    itemsName: 'videos',
                                    isCompact: true,
                                    isStriped: true,
                                }}
                                bulkActions={{
                                    enabled: true,
                                    selectedIds: selectedRows,
                                    setSelectedIds: setSelectedRows,
                                    buttons: getBulkActionButtons(),
                                }}
                                dataLoading={{
                                    active: loading,
                                    text: 'Fetching your data, please wait...',
                                }}
                                dataError={{
                                    active: !!error,
                                    text: `There was an error: ${error?.message || 'network error'}`,
                                }}
                                columns={tableColumns}
                                data={data?.mutations.results || []}
                            />
                            {data && (
                                <TableFooter
                                    rows={data.mutations.paging.count}
                                    paging={{
                                        limit: data.mutations.paging.limit,
                                        page: data.mutations.paging.page,
                                    }}
                                    setPaging={setPaging}
                                    itemsName="videos"
                                />
                            )}
                        </>
                    )}
                />
            </Layout>
            {
                openedModal
                && openedModal === VideoModalVariants.add
                && (
                    <AddVideoModal<VideoModalVariants>
                        setModal={setOpenedModal}
                        currentChannel={channel}
                    />
                )
            }
            {
                openedModal
                && openedModal === VideoModalVariants.export
                && (
                    <VideosExportModal<VideoModalVariants>
                        setModal={setOpenedModal}
                        setMutatiosToExport={setMutationsToExport}
                        allRowsCount={data?.mutations.paging.count || 0}
                        mutationIds={mutationsToExport === 'all' ? 'all' : selectedRows || mutationsToExport || []}
                        getFilter={() => getQueryVariables().filter}
                    />
                )
            }
        </>
    );
};

export default ChannelVideosPage;
